import { Link } from "gatsby"
import * as React from "react"
import Helmet from "react-helmet"
import Button from "../components/utils/button";
import '../styles/contact/success.scss';

class Success extends React.Component {
    render(){
        return (
            <>
                <section>
                    <div className="success ripple__front">
                        <h1>Votre message à bien était envoyé 💌!</h1>
                        <br/>
                        <br/>
                        <Button to="/" value="Retour acceuil"></Button>
                    </div>
                </section>
            </>
        )
    }
}

export default Success